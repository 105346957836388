import React from 'react'

const Tag = ({
  className = '',
  label = '',
  color = '',
  backgroundColor = '',
  style = 'square',
  forceCSS,
  ...props
}) => {
  return (
    <div
      className={ `Tag Tag--${style} ${className}` } { ...props }
      style={ { color, backgroundColor } }
    >
      { label }
    </div>
  )
}

export default Tag
