import React from 'react'
import Lottie from 'react-lottie'

import Title from 'components/Title'
import Paragraph from 'components/Paragraph'
import Button from 'components/Button'
import Tag from 'components/Tag'
import Container from 'components/Container'

import useWindowSize from '../utils/useWindowSize'

const Hero = ({
  tag = '',
  tagColor = '',
  tagBackgroundColor = '',
  align = 'center',
  title = '',
  subtitle,
  ctaLabel,
  ctaTo,
  background = '',
  backgroundMobile = '',
  fullHeight = false
}) => {
  const size = useWindowSize()
  const isMobile = size.width <= 400

  const renderBackground = () => {
    const bgDesktop = background
    const bgMobile = backgroundMobile || background

    if (bgDesktop.endsWith('.json') && bgMobile.endsWith('.json')) {
      const defaultOptions = {
        loop: true,
        autoplay: true
      }

      return (
        <>
          <div className='Hero-backgroundLottie Hero-backgroundLottie--desktop'>
            <Lottie
              options={ { ...defaultOptions, path: bgDesktop } }
              height='auto'
              width='100%'
              isPaused={ isMobile }
            />
          </div>
          <div className='Hero-backgroundLottie Hero-backgroundLottie--mobile'>
            <Lottie
              options={ { ...defaultOptions, path: bgMobile } }
              height='auto'
              width='100%'
              isPaused={ !isMobile }
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='Hero-backgroundImage' style={ { backgroundImage: `url(${bgDesktop})` } } />
          <div className='Hero-backgroundMobile' style={ { backgroundImage: `url(${bgMobile})` } } />
        </>
      )
    }
  }

  return (
    <div className={ `Hero ${fullHeight && 'Hero--fullHeight'}` }>
      { renderBackground() }
      <Container size={ align === 'center' ? 'small' : 'large' } className={ `Hero-content Hero-content--${align}` }>
        { tag && (<Tag className='Hero-tag' color={ tagColor } backgroundColor={ tagBackgroundColor } label={ tag } />) }
        <Title tag='h1' className='Hero-title'>{ title }</Title>
        { subtitle && (<Paragraph className='Hero-subtitle' size='normal'>{ subtitle }</Paragraph>) }
        { ctaTo && (<Button color='orange' to={ ctaTo }>{ ctaLabel }</Button>) }
      </Container>
    </div>
  )
}

export default Hero
